import Vue from 'vue'
import Vuex from 'vuex'
import banner from '@/store/modules/banner.js'
import product from '@/store/modules/product.js'
import shop from '@/store/modules/shop.js'
import sort from '@/store/modules/sort.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    banner,
    product,
    shop,
    sort
  }
})
