<template>
  <div>
    <ul>
        <li @click="$router.push({path:'/prodetail',query:{id:item1.id}})" v-for="(item1,idnex1) in item" :key="idnex1">
          <img :src="item1.img" alt="">
          <div class="right">
            <h3>{{item1.title}}</h3>
            <p>{{item1.classify}}</p>
            <span>{{item1.price}}</span>
          </div>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Array
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
 ul{
  margin: 0 15px;
    li{
      display: flex;
      padding-bottom: 15px;
      padding-top: 15px;
      border-bottom: 1px solid #f5f5f5;
      &:first-child{
        padding-top: 0;
      }
      img{
        margin-right: 10px;
        width: 100px;
        height: 100px;
        border-radius: 3px;
      }
      h3{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        font-size: 16px;
        height: 48px;
      }
      p{
        font-size: 13px;
        color: #999;
        margin: 4px 0;
      }
      span{
        font-size: 16px;
        color: red;
        font-weight: 600;
      }
    }
  }
</style>
