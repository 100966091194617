import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 打包后会出现空白，没重定向
  {
    path: '/',
    redirect: '/index',
    component: () => import('@/views/LayoutPage.vue'),
    children: [{ path: '/index', name: 'index', component: () => import('@/views/layout/IndexPage.vue') },
      { path: '/sort', component: () => import('@/views/layout/SortPage.vue') },
      { path: '/store', component: () => import('@/views/layout/StorePage.vue') },
      { path: '/car', component: () => import('@/views/layout/CarPage.vue') },
      { path: '/user', component: () => import('@/views/layout/UserPage.vue') }]
  },
  {
    path: '/prolist',
    component: () => import('@/views/list/ProductListPage.vue')
  },
  {
    path: '/questlist',
    component: () => import('@/views/list/QuestListPage.vue')
  },
  {
    path: '/prodetail',
    component: () => import('@/views/detail/ProductDetail.vue')
  },
  {
    path: '/storedetail',
    component: () => import('@/views/detail/StoreDetail.vue')
  },
  {
    path: '/aboutstore',
    component: () => import('@/views/about/AboutStorePage.vue')
  },
  {
    path: '/questdetail',
    component: () => import('@/views/detail/QuestDetail.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/login/LoginPage.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/register/RegisterPage.vue')
  },
  {
    path: '/verifylogin',
    component: () => import('@/views/login/VerifyLoginPage.vue')
  },
  {
    path: '/useragree',
    component: () => import('@/views/detail/UserAgreeDetail.vue')
  },
  {
    path: '/suborder',
    component: () => import('@/views/order/SubOrderPage.vue')
  },
  {
    path: '/checkstand',
    component: () => import('@/views/order/CheckStandPage.vue')
  },
  {
    path: '/buyorder',
    component: () => import('@/views/order/BuyOrderPage.vue')
  },
  {
    path: '/userwallet',
    component: () => import('@/views/money/UserWalletPage.vue')
  },
  {
    path: '/walletcash',
    component: () => import('@/views/money/WalletCashPage.vue')
  },
  {
    path: '/walletrecharge',
    component: () => import('@/views/money/WalletRechargePage.vue')
  },
  {
    path: '/collectpro',
    component: () => import('@/views/collect/CollectProPage.vue')
  },
  {
    path: '/collectshop',
    component: () => import('@/views/collect/CollectShopPage.vue')
  },
  {
    path: '/addorder',
    component: () => import('@/views/order/AddOrderPage.vue')
  },
  {
    path: '/pubquest',
    component: () => import('@/views/quest/PublishQuestPage.vue')
  },
  {
    path: '/pubquesttwo',
    component: () => import('@/views/quest/PublishQuestTwoPage.vue')
  },
  {
    path: '/toubiao',
    component: () => import('@/views/quest/TouBiaoPage.vue')
  },
  {
    path: '/newstore',
    component: () => import('@/views/store/NewStorePage.vue')
  },
  {
    path: '/useritem',
    component: () => import('@/views/user/UserItemPage.vue')
  },
  {
    path: '/userauth',
    component: () => import('@/views/user/UserAuthPage.vue')
  },
  {
    path: '/realname',
    component: () => import('@/views/user/RealNamePage.vue')
  },
  {
    path: '/qiyereal',
    component: () => import('@/views/user/QiYeRealPage.vue')
  },
  {
    path: '/setpassword',
    component: () => import('@/views/user/SetPasswordPage.vue')
  },
  {
    path: '/usercontact',
    component: () => import('@/views/user/UserContactPage.vue')
  },
  {
    path: '/aboutus',
    component: () => import('@/views/about/AboutUsPage.vue')
  },
  {
    path: '/contactus',
    component: () => import('@/views/about/ContactUsPage.vue')
  }
]

const router = new VueRouter({
  routes,
  // 路由切换页面的滚动行为
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      // console.log(savedPosition)
      // 如果之前有保存滚动位置，则恢复到之前保存的位置
      return savedPosition
    } else {
    // 否则，默认滚动到页面顶部
      return { x: 0, y: 0 }
    }
  }
})
// 修复 重复渲染路由 报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
