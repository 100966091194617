import { GetShop, GetShopDetail } from '@/api/shop'
export default {
  namespaced: true,
  state: {
    shopItem: {} // 店铺信息
  },
  mutations: {
    // 获取店铺信息
    setshopItem (state, item) {
      state.shopItem = item
    }
  },
  actions: {
    // 获取店铺信息
    async  getshop ({ commit }, item) {
      const res = await GetShop(item)
      // console.log(res.data)
      commit('setshopItem', res.data)
    },
    // 获取店铺信息
    async  getshopdetail ({ commit }, id) {
      const res = await GetShopDetail(id)
      return res.data
    }
  }
}
