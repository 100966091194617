<template>
  <div class="pro" >
    <img src="../assets/image/shoplist.png" alt="">
    <div class="item">
      <h3>商务邦系统 异业合作 BD商务 APP拉新推广地推 社群人脉 接单放单 任务发布 平台 系统 源码 软件 开发</h3>
        <div class="num">
          <p>¥2980.00</p>
          <span>x1</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
     .pro{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        padding: 15px 0;
        img{
           width: 80px;
            height: 80px;
            border-radius: 5px;
            margin: 0 10px 0 0;
        }
        .item{
          h3{
            font-size: 16px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
          .num{
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
            color: red;
            font-size: 16px;
          }
          span{
            display: block;
            color: #999;
            font-size: 12px;
            margin-right: 10px;
          }
          }

        }
      }
</style>
