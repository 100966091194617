<template>
  <div>
    <van-nav-bar @click-right="clickRight"  fixed border :title="title"  left-arrow :right-text="rightText">
      <template #left>
        <van-icon @click="$router.back()"  name="arrow-left" size="5vw"/>
        <van-icon @click="$router.push('/index')" name="wap-home-o" size="5vw"/>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    rightText: {
      type: String,
      default: ''
    }
  },
  methods: {
    clickRight () {
      this.$router.push('/pubquesttwo')
    }
  }
}
</script>

<style lang="less" scoped>
// 头部标题
.van-nav-bar .van-icon{
  color: #000;
}
.van-icon:before{
  margin-right: 8px;
}
/deep/.van-nav-bar__text{
  color: #999;
  font-size: 13px;
}
</style>
