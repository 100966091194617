
import request from '../utils/request'

// 获取默认的商品数据 每次请求10条
export const GetProduct = (item) => request.get('/products/products/', { params: item })

// 获取商品详情的数据
export const GetProdetail = (id) => request.get(`/products/products/${id}`)

// 获取筛选的商品数据
export const GetselProduct = (item) => request.get(`/products/products/?page=1&size=9999&${item}`)
// 获取点击分类获取的商品数据
// export const GetselProduct = (item) => request.get(`/products/products/?page=${}&size=10&${item}`)
