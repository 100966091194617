import Vue from 'vue'
import SelectSort from '../components/SelectSort.vue'
import ShopItem from '../components/ShopItem.vue'
import TopTitle from '../components/TopTitle.vue'
import BuyProduct from '../components/BuyProduct.vue'
// 复制文本的插件
import VueClipboard from 'vue-clipboard2'
Vue.component('SelectSort', SelectSort)
Vue.component('ShopItem', ShopItem)
Vue.component('TopTitle', TopTitle)
Vue.component('BuyProduct', BuyProduct)
Vue.use(VueClipboard)
