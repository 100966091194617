import { GetSort } from '@/api/sort'
export default {
  namespaced: true,
  state: {
    sortList: {}
  },
  mutations: {
    // 获取分类目录结构
    setsortList (state, item) {
      state.sortList = item
    }
  },
  actions: {
    // 获取分类目录列表
    async  getsort ({ commit }) {
      const res = await GetSort()
      // console.log(res.data.data, 88)
      commit('setsortList', res.data.data)
    }
  }
}
