import { GetProduct, GetProdetail, GetselProduct } from '@/api/product'
export default {
  namespaced: true,
  state: {
    products: {} // 商品的数据
  },
  mutations: {
    // 获取商品列表
    setproducts (state, item) {
      state.products = item
    }
  },
  actions: {
    // 获取商品列表
    async  GetProduct ({ commit }, item) {
      const res = await GetProduct(item)
      // console.log(res.data)
      commit('setproducts', res.data)
    },
    // 获取商品详情的数据
    async getprodetail ({ commit }, id) {
      const res = await GetProdetail(id)
      return res.data
    },
    async  getselProduct ({ commit }, item) {
      const res = await GetselProduct(item)
      return res.data.results
    }
  }
}
