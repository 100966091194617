<template>
  <div>
    <div v-for="(item1,index1) in item" :key="index1" class="select_sort">
      <h3>{{item1.second}}</h3>
      <ul>
        <li @click="$router.push({path:'/prolist',query:{id:item2.id,from:'sort'}})" v-for="item2 in item1.three" :key="item2.id">{{item2.three}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
    .select_sort{
      h3{
        margin-top: 15px;
        font-size: 16px;
        position: relative;
        padding-left: 15px;
        &::before{
          position: absolute;
          top: 7px;
          left: 0;
          width: 5px;
          height: 12px;
          border-radius: 16px;
          background: @base-color;
          content: "";
        }
        margin-bottom: 15px;
      }
      ul{
        li{
          background-color: #eee;
          font-size: 13px;
          border-radius: 3px;
          padding: 6px 9px;
          margin-right: 8px;
          margin-bottom: 8px;
          display: inline-block;
          color: #666;
        }
      }
    }
</style>
