import { Uploader, Picker, RadioGroup, Radio, SwipeCell, Checkbox, CheckboxGroup, Form, Button, Field, Toast, GoodsAction, GoodsActionIcon, GoodsActionButton, Popup, Tabs, Tab, Icon, TreeSelect, NavBar, List, SwipeItem, Swipe, Tabbar, TabbarItem, Search } from 'vant'
import Vue from 'vue'
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Search)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(List)
Vue.use(NavBar)
Vue.use(TreeSelect)
Vue.use(Icon)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Popup)
Vue.use(GoodsAction)
Vue.use(GoodsActionButton)
Vue.use(GoodsActionIcon)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Button)
Vue.use(Form)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(SwipeCell)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Picker)
Vue.use(Uploader)
// 提示挂载到原型上
Vue.prototype.$toast = Toast
